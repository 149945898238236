import React from "react"
import PropTypes from "prop-types"
import Layout from "../components/layout";
import Container from "../components/container"

// Components
import {Helmet} from "react-helmet"
import {Link, graphql} from "gatsby"
import Metatags from "../components/Metatags";

const TagsPage = ({
        data: {
            allWpTag: {nodes, totalCount},
            site: {
                siteMetadata: {title},
            },
        },
    }) => (
    <Layout>

        <Metatags
            title="Goal-Based Popup Builder | Powerful Lead Generation Tool"
            description = "Start creating lead capturing forms and exit intent popups for your website. Drive sales and conversions, reduce cart abandonment, grow your email list and do more using multiple popup form types and pre-existing popup templates."
            keywords= {[`exit popup`, `popup maker`, `free exit popup generator`, `email optins Features Page`]}
            url = "https://www.optinly.com"
            pathname = "/tags/"
        />
        
        <Container>
            <div>
                <Helmet title={title}/>
                <div>
                    <h1>Tags</h1>
                    <ul>
                        {nodes.map(tag => (
                            <li key={tag.name}>
                                <Link to={
                                    tag.uri == '/tag/content-marketing/'?"/tag/content-marketing-2/":
                                    tag.uri == '/tag/types-of-marketing/'?"/tag/content-marketing-2/":
                                    tag.uri == '/tag/email-marketing/'?"/tag/email-marketing-2/":

                                    tag.uri}>
                                    {tag.name} 
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </Container>
    </Layout>
)

TagsPage.propTypes = {
    data: PropTypes.shape({
        site: PropTypes.shape({
            siteMetadata: PropTypes.shape({
                title: PropTypes.string.isRequired,
            }),
        }),
    }),
}

export default TagsPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allWpTag(limit: 20) {
        nodes {
            name
            slug
            uri
            count
        }
        totalCount
    }
  }
`